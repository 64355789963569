@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
 
.no-scroll {
  overflow: hidden;
}
html {
  overflow-x: hidden;
  }
 
 
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "BaiJamjuree";
  font-style: normal;
  font-weight: 200;
  src: local("BaiJamjuree"), url("../../gpstore-web//src/font/Baijamjuree/BaiJamjuree-Medium.ttf") format("truetype");
}
 
@font-face {
  font-family: "BaiJamjuree";
  font-style: normal;
  font-weight: 300;
  src: local("BaiJamjuree"), url("../../gpstore-web/src/font/Baijamjuree/BaiJamjuree-Regular.ttf") format("truetype");
}
 
@font-face {
  font-family: "BaiJamjuree";
  font-style: normal;
  font-weight: 400;
  src: local("BaiJamjuree"), url("../../gpstore-web/src/font/Baijamjuree/BaiJamjuree-Medium.ttf") format("truetype");
}
 
@font-face {
  font-family: 'BaiJamjuree';
  font-style: normal;
  font-weight: 500;
  src: local("BaiJamjuree"), url("../../gpstore-web/src/font/Baijamjuree/BaiJamjuree-Bold.ttf") format("truetype");
}
 
@font-face {
  font-family: 'BaiJamjuree';
  font-style: normal;
  font-weight: 600;
  src: local("BaiJamjuree"), url("../../gpstore-web/src/font/Baijamjuree/BaiJamjuree-SemiBold.ttf") format("truetype");
}
 
@font-face {
  font-family: 'BaiJamjuree';
  font-style: normal;
  font-weight: 700;
  src: local("BaiJamjuree"), url("../../gpstore-web/src/font/Baijamjuree/BaiJamjuree-Regular.ttf") format("truetype");
}
 
* {
  font-family: "BaiJamjuree";
}
 
/* Montserrat */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: local("Montserrat"), url("../../gpstore-web/src/font/Montserrat/Montserrat-ExtraLight.ttf") format("truetype");
}
 
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat"), url("../../gpstore-web/src/font/Baijamjuree/BaiJamjuree-Regular.ttf") format("truetype");
}
 
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat"), url("../../gpstore-web/src/font/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
}
 
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat"), url("../../gpstore-web/src/font/Montserrat/Montserrat-Bold.ttf") format("truetype");
}
 
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local("Montserrat"), url("../../gpstore-web/src/font/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
}
 
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat"), url("../../gpstore-web/src/font/Montserrat/Montserrat-Medium.ttf") format("truetype");
}
 
/* poppins*/
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: local("Montserrat"), url("../../gpstore-web/src/font/Poppins/Poppins-Light.ttf") format("truetype");
}
 
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat"), url("../../gpstore-web/src/font/Poppins/Poppins-Regular.ttf") format("truetype");
}
 
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat"), url("../../gpstore-web/src/font/Poppins/Poppins-SemiBold.ttf") format("truetype");
}
 
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat"), url("../../gpstore-web/src/font/Poppins/Poppins-Bold.ttf") format("truetype");
}
 
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat"), url("../../gpstore-web/src/font/Poppins/Poppins-Thin.ttf") format("truetype");
}
 
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat"), url("../../gpstore-web/src/font/Poppins/Poppins-Black.ttf") format("truetype");
}
 

body {
  overflow-x: hidden;
}
 
.img-container {
  width: 100%;
}
 
/* about us css */
 
.bg-img {
  position: relative;
  width: 100%;
}
 
.about-us-img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}
 
.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  z-index: 1;
  border-radius: 15px;
}
 
.img-content {
  position: absolute;
  bottom: 0;
  left: 50%;
  top: 70%;
  transform: translateX(-50%);
  text-align: center;
  padding: 20px;
  color: white;
  z-index: 2;
}
 
.about-us-heading {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 800;
  line-height: 24.38px;
  text-align: center;
  color: #FBC300;
}
 
.more-about {
  width: 650px;
  height: 118px;
}
 
.more-about div {
  font-family: "Montserrat";
  font-size: 48px;
  font-weight: 800;
  line-height: 58.51px;
  text-align: center;
  color: #FFFFFF;
}
 
.col-lg-6 h1 {
  font-size: 3rem;
}
 
.relative-img {
  position: relative;
  width: 90%;
  border-radius: 15px;
  overflow: hidden;
  /* margin-top: 12px; */
}
 
.absolute-img {
  position: absolute;
  left: 50vh;
  /* top: 136vh; */
  top: 120vh;
}
 
.absolute-img img {
  height: 85vh;
  width: 55vh;
}
 
.payment-img, .product-img{
  margin-left: 50px;
}
.border-lines {
  border: 1px solid #0c0c0c;
  opacity: 0.3;
  /* margin-top: 21px !important;
  margin-bottom: 15px !important; */
  transform: rotate(90deg);
  margin-left: 60px;
  margin-bottom: 13px;
 
}
 
.about-us-details-content p {
  color: #4D4D4D;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.5px;
  text-align: justify;
}
 
.details-content p {
  color: #8C8C8C;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
}
 
.continue-btn,
.contact-us-btn {
  text-align: center !important;
  border: 1px solid #B3B3B3 !important;
  color: #8C8C8C !important;
  border-radius: 8px !important;
  width: 100%;
  height: 50px;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 22.5px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
 
.continue-btn:hover,
.contact-us-btn:hover {
  background-color: #FBC300 !important;
  color: #6D4E00 !important;
}
 
/* cancellation-policy-page */
 
.bg-img {
  position: relative;
  width: 100%;
}
 
.privacy-policy-img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}
 
.cancel-policy-condition-heading {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 800;
  line-height: 24.38px;
  text-align: center;
  color: #FBC300;
}
 
.cancel-policy-details-content p {
  color: #4D4D4D;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.5px;
  text-align: justify;
}
 
/* terms and conditions */
.bg-img {
  position: relative;
  width: 100%;
}
 
.terms-condition-img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}
 
.terms-condition-heading {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 800;
  line-height: 24.38px;
  text-align: center;
  color: #FBC300;
}
.fw-bold {
  font-weight: bold !important;
  font-size: 15px;
  font-family: "Montserrat";
}
 
.terms-conditions-details-content p,
.alcohol-policy-details-content p,
.cookies-details-content p,
.return-details-content p {
  color: #4D4D4D;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.5px;
  text-align: justify;
}
 
/* privacy policy page */
 
.bg-img {
  position: relative;
  width: 100%;
}
 
.privacy-policy-condition-heading {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 800;
  line-height: 24.38px;
  text-align: center;
  color: #FBC300;
}
 
.privacy-policy-details-content p {
  color: #4D4D4D;
  font-size: 16px;
  font-weight: 700;
  line-height: 22.5px;
  text-align: justify;
}
 
/* Mobile responsives */
/* @media (min-width:375px) and (max-width:667px) {
  .absolute-img {
    left: 23vh !important;
    top: 70vh !important;
  }
 
  div.mt-5:nth-child(1)>div:nth-child(1)>div:nth-child(2) {
    margin-top: 10vh;
  }
 
  .col-lg-6 h1 {
    font-size: 1.5rem !important;
  }
} */
 
@media (max-width: 768px) {
  .about-us-heading {
    font-size: 14px;
    line-height: 20px;
  }
 
  .absolute-img img {
    height: 30vh;
  }
 
  .more-about {
    width: 100%;
    height: auto;
  }
 
  .more-about div {
    font-size: 15px;
    line-height: 25px;
  }
 
  .img-container {
    width: 100%;
    height: 26vh;
  }
 
  .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 26vh;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    z-index: 1;
    border-radius: 15px;
  }
 
  .bg-img {
    height: 26vh;
  }
 
  .img-content {
    position: absolute;
    top: 45%;
    text-align: center;
    color: white;
    z-index: 2;
    width: 100%;
  }
 
  .about-us-img {
    /* border-radius: 3px; */
    object-fit: cover;
    height: 26vh;
  }
  .payment-img, .product-img{
    margin-left: 0px;
 }
.border-lines {
    border: 1px solid #0c0c0c;
    opacity: 0.3;
    transform: rotate(90deg);
    margin: 30px auto;
    width: 37px;
  }
  .continue-btn,
  .contact-us-btn {
    background-color: #FBC300 !important;
    color: #6D4E00 !important;
  }
 
  .col-lg-6 h1 {
    font-size: 2rem;
  }
 
  .img-fluid-container {
    height: 50vh;
  }
 
  .relative-img {
    width: 90%;
  }
 
  .absolute-img {
    width: 55%;
    left: 19vh;
    top: 60vh;
  }
 
  .cancel-policy-condition-heading {
    font-size: 14px;
    line-height: 20px;
  }
 
  .terms-condition-heading {
    font-size: 14px;
    line-height: 20px;
  }
 
  .terms-condition-img {
    /* border-radius: 3px; */
    object-fit: cover;
    height: 26vh;
  }
 
 
  .privacy-policy-condition-heading {
    font-size: 14px;
    line-height: 20px;
  }
 
  .privacy-policy-img {
    /* border-radius: 3px; */
    object-fit: cover;
    height: 26vh;
  }
 
  div.mt-5:nth-child(4) {
    gap: 20px;
  }
}
 
/* tab */
@media only screen and (min-width: 801px) and (max-width: 992px) {
 
  .privacy-policy-img,
  .terms-condition-img,
  .about-us-img {
    height: 25vh;
    object-fit: cover;
  }
 
  .bg-img {
    height: 25vh;
  }
 
  .img-container {
    height: 25vh;
  }
 
  .img-content {
    top: 60%;
  }
 
  .more-about div {
    font-size: 40px;
  }
 
  div.mt-5:nth-child(4) {
    gap: 20px;
  }
 
  .relative-img {
    width: 95%;
  }
 
  .relative-img>img:nth-child(1) {
    width: 80%;
  }
 
  .absolute-img {
    left: 33vh !important;
    top: 46vh !important;
    width: 39vh;
  }
 
  .absolute-img {
    left: 35vh !important;
    top: 44vh !important;
    width: 39vh;
  }
   
  .continue-btn,
  .contact-us-btn {
    background-color: #FBC300 !important;
    color: #6D4E00 !important;
  }
 
 .payment-img, .product-img{
margin: auto;
}
  div.mt-5:nth-child(1)>div:nth-child(1)>div:nth-child(2) {
    margin-top: 10vh;
  }
}
 
 
 /* for lablets */
@media only screen and (min-width: 596px) and (max-width: 992px){
  .border-lines {
      border: 1px solid #0c0c0c;
      opacity: 0.3;
      /* margin-top: 21px !important;
      margin-bottom: 15px !important; */
      transform: rotate(90deg);
      margin-left: 48% !important;
      margin-top: 30px;
      margin-bottom: 30px;
      width: 37px;

 
    }

}
